<template>
  <v-container>
    <v-row justify="start">
      <v-col cols="5" sm="3" md="2">
        <v-img :src="item.imgUrl" ></v-img>
      </v-col>
      <v-col cols="auto">
        <v-card>
          <v-card-title>{{ item.name }}</v-card-title>
          <!-- <v-card-text class="pt-5">
            <div class="text--primary body-1">波兰蠢驴，叶奈法</div>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <baseTitle name="相关图片" icon="fa-image" />
        <v-slide-group center-active show-arrows>
          <!-- <v-slide-item v-for="n in 25" :key="n" v-slot:default="{ active, toggle }">
            <v-img class="mx-1" @click="toggle" width="300" src="/imgs/game/292030/2020-02-12_093411.PNG"></v-img>
          </v-slide-item> -->
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <baseTitle name="相关视频" icon="fa-video-camera" />
        <v-slide-group center-active show-arrows>
          <!-- <v-slide-item v-for="n in 25" :key="n" v-slot:default="{ active, toggle }">
            <v-img class="mx-1" @click="toggle" width="300" src="/imgs/game/292030/2020-02-12_093411.PNG"></v-img>
          </v-slide-item> -->
       </v-slide-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <baseTitle name="相关说说" icon="fa-comment" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import baseTitle from '@/components/base/title'

export default {
  name: 'GameDetail',
  components: {
    baseTitle
  },
  props: ['id'],
  data: () => ({
    id: '',
    item: {}
  }),
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$http.Game.getDetail(this.id).then(res => {
        this.item = res.data
      })
    }
  }
}
</script>

<style>

</style>
